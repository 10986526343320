import React, { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';
import usePageBottom from "../core/usePageBottom";
import Images from './Images'

const GridInfinite = ({ ...props }) => {
  const limit = 20
  const isPageBottom = usePageBottom();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const tokenS = PubSub.subscribe("SEARCH", onSearch)

    return () => {
      PubSub.unsubscribe(tokenS)
    }
  }, [])

  const onSearch = () => {
    setPage(1)
  }

  useEffect(() => {
    if (!isPageBottom) return;
    setPage(page + 1)
  }, [isPageBottom])

  return (
    <div className="grid-infinite">
        <Images limit={limit} page={page} {...props} />
    </div>
  );
};

export default GridInfinite;