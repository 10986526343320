export const ImageType = {
  original: 'original', // Original uploaded image
  optimized: 'optimized', // Image same size but optimzed
  small: 'small', // Image with width to 375px and optimzed
  medium: 'medium', // Image with width to 768px and optimzed
  large: 'large', // Image with width to 1440px and optimzed
};

export function getImageKey({ imageId, filename, type }) {
  return `${imageId}/${type}-${imageId}.${filename.split('.').pop()}`;
}

export function getImageUrl(params) {
  return `https://${process.env.GATSBY_CDN_URL}/${getImageKey(params)}`;
}
