import React, { useState, useEffect, useRef } from 'react';
import MapFile from '../images/map-arn.inline.svg'

const MiniMap = ({ input }) => {
  const [show, setShow] = useState(false)
  const miniMapRef = useRef();

  useEffect(() => {
    miniMapRef.current.querySelectorAll("g > *").forEach(element => {
      element.classList.remove("is-active")

      if (input && input.value === element.id) {
        element.classList.add("is-active")
        setShow(true)
      }
    })

  }, [input])

  return (
    <div id="mini-map" className={show ? '' : 'hidden'} ref={miniMapRef}>
      <MapFile />
    </div>
  );
};

export default MiniMap;