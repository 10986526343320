import React, { useContext } from 'react';
import PubSub from 'pubsub-js';
import { FiltersContext } from '../components/Layout'

const ImageFiltres = ({input}) => {
  const _FiltersContext = useContext(FiltersContext)
  const { filters, dispatch } = _FiltersContext

  const isFilterSelected = (filter) => {
    const filterFiltered = filters.filter(el => el.id === filter.id)
    return filterFiltered.length > 0
  }

  const _onFilterClick = (filter, _isFilterSelected) => {
    if(_isFilterSelected){
      dispatch({type: "REMOVE", payload: filter})
    }else{
      dispatch({type: "ADD", payload: filter})
    }
  }

  const _colorize = (target, color) => {
    if(!color)return
    target.style.background = color
    target.style.color = "white"
    target.style.borderColor = color
  }

  const _resetColorize = (target, color) => {
    if(!color)return
    target.style.background = "white"
    target.style.color = color
  }

  const _submit = () => {
    PubSub.publish("SEARCH", filters)
  }

  return (
    <ul className="image-filters x xw">
      {input.map((filter,i) => {
        const _isFilterSelected = isFilterSelected(filter)
        return(
        <li key={i} 
        style={{color: filter.color}}
        onMouseEnter={(e) => _colorize(e.currentTarget, filter.color)}
        onMouseLeave={(e) => _resetColorize(e.currentTarget, filter.color)}
        >
          <button 
          className={`outline ${_isFilterSelected ? 'is-selected' : ''}`}  
          onClick={() => _onFilterClick(filter, _isFilterSelected)}>
            <span>{filter.label}</span>
            {_isFilterSelected &&
              <span className="chip">×</span> 
            }
            {!_isFilterSelected &&
              <span className="chip">+</span>
            }
          </button>
          {_isFilterSelected && <span 
          className="submit curp" 
          onClick={_submit}
          >→</span>}
        </li>
      )})}
    </ul>
  );
};

export default ImageFiltres;