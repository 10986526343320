import React, { useContext, useEffect } from 'react';
import SEO from '../components/seo';

import { FiltersContext } from '../components/Layout'
import GridInfinite from '../components/GridInfinite';
import PubSub from 'pubsub-js';

const Liste = () => {
  const _FiltersContext = useContext(FiltersContext)
  const { filters } = _FiltersContext
  const filtersId = filters.map(el => el.id)

  useEffect(() => {
    setTimeout(() => {
      const filtersId = filters.map(el => el.id)
      PubSub.publish("FILTERS", filtersId)
    }, 250)
  }, [])
  return (
    <div className="liste">
      <SEO
      pageTitle="LISTE"
      pageDescription="Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quisquam."
      pageImage=""
      page={true}
      template="template-liste"
      />
      <GridInfinite filters={filtersId} />
    </div>
  );
};

export default Liste;