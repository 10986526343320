import React, { useState, useEffect } from 'react';

export default function usePageBottom() {
  const [bottom, setBottom] = useState(false);
  // console.log(bottom)

  useEffect(() => {
    function handleScroll() {
      // console.log(window.innerHeight, window.pageYOffset, document.body.scrollHeight)
      const isBottom =
        window.innerHeight + window.pageYOffset >=
        document.body.scrollHeight - offset;
      // (window.innerHeight + window.scrollY) >= document.body.scrollHeight
      // window.innerHeight + document.documentElement.scrollTop ===
      // document.documentElement.offsetHeight;
      setBottom(isBottom);
    }
    let offset = window.innerHeight / 2;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return bottom;
}
