import React, { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';
import Card from './Card';
import ImageModal from './ImageModal';
import { useApolloClient, gql } from '@apollo/client';

function useLoadImages() {
  const client = useApolloClient();
  
  return async (limit, page, filters) => {
    const variables = {
      first: limit,
      page,
      ...(filters.length && { filters }),
      populateFilters: true,
      orderBy: [
        {field:'FILENAME', order: 'ASC'}, 
        {field:'PROMOTE', order: 'DESC'}
      ]
    };

    const result = await client.query({query: gql`query images(
      $orderBy: [ImagesOrderByOrderByClause!]
      $populateFilters: Boolean
      $filters: [ID!]
      $first: Int!
      $page: Int
    ){
      images(orderBy: $orderBy, populateFilters: $populateFilters, first: $first, page:$page, filters: $filters){
          id
          name
          filename
          imageId
          promote
          legend
          filters {
            id
            label
            value
            hidden
            ordering
            color
            icon
            parentId
          }
      }
    }`,variables});
    if (!result.data || !result.data.images) {
      throw new Error('No data');
    }

    return result.data.images;
  }
}

const MsgError = ({ error }) => {
  return (
    <div className="msg error">
      <h3>Erreur</h3>
      <p>{JSON.stringify(error)}</p>
    </div>
  );
};
const MsgLoading = () => {
  return (
    <div className="msg loading">
      <h3>Loading...</h3>
    </div>
  );
};

const MsgNoImages = () => {
  return (
    <div className="msg loading">
      <h3>0 images :(</h3>
    </div>
  );
};

const Images = ({ limit, page, filters }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [currentFilters, setFilters] = useState(filters);
  const loadImages = useLoadImages();

  /**
   * lifecycle of filters + infinite scroll
   */
  useEffect(() => {
    const tokenF = PubSub.subscribe('FILTERS', onFilters);
    const tokenS = PubSub.subscribe('SEARCH', onSearch);

    return () => {
      PubSub.unsubscribe(tokenF);
      PubSub.unsubscribe(tokenS);
    };
  }, [currentFilters]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const onFilters = (e, d) => {
    setFilters(d);
  };
  const onSearch = (e, d) => {
    setData([]);
    fetchData();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await loadImages(limit, page, currentFilters);
      setData((prevState) => [...prevState, ...res]);
      setError(undefined);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  return (
    <>
      {error && <MsgError error={error} />}
      {!error && data && (
        <div className="images">
          {!isLoading && data.length === 0 && <MsgNoImages />}
          {isLoading && data.length === 0 && <MsgLoading />}

          {data.length > 0 && (
            <div className="grid">
              <div className="row">
                {data.map((image, i) => (
                  <div className="col-xs-12 col-sm-6 col-md-3" key={i}>
                    <Card {...image} />
                  </div>
                ))}
              </div>
              {isLoading && <MsgLoading />}
              <ImageModal />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Images;
