import React from 'react';
import PubSub from 'pubsub-js';
import ImageResponsive from './ImageResponsive'
import ImageFiltres from './ImageFiltres'

const Card = (props) => {
  
  const _popup = () => {
    PubSub.publish('POPUP', props);
  };
  
  const hasFilters = props.filters && props.filters.length > 0

  return (
    <div className="card">
      <div className="card-image" onClick={() => _popup()}>
      <ImageResponsive image={props} />
      </div>
      <div className="card-body">
        {hasFilters && <ImageFiltres input={props.filters} />}
      </div>
    </div>
  );
};

export default Card;
