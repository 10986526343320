import React, { useEffect, useState } from 'react';
import { getImageUrl, ImageType } from './GetImageUrl';
import PubSub from 'pubsub-js';
import ImageFiltres from './ImageFiltres'
import MiniMap from './MiniMap';
const ReactMarkdown = require('react-markdown')

const ImageModal = ({ input }) => {
  const [active, setActive] = useState(false);
  const [img, setImg] = useState();
  const [scrollTop, setScrollTop] = useState()
  useEffect(() => {
    const token = PubSub.subscribe('POPUP', (e, d) => {
      setImg(d);
      _open();
    });

    document.addEventListener("keydown", _onKeyDown)

    return () => {
      PubSub.unsubscribe(token);
      document.removeEventListener("keydown", _onKeyDown)
    }
  }, []);

  useEffect(() => {
    if (active) {
      setScrollTop(window.pageYOffset)
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
      window.scrollTo(0, scrollTop)
    }
  }, [active])

  const _open = () => setActive(true);
  const _close = () => setActive(false);

  const _onKeyDown = (e) => {
    if (e.keyCode == 27) _close()
  }
  const hasFilters = img && img.filters && img.filters.length > 0
  const filterZone = hasFilters ? img.filters.filter(el => el.color === "#f00400") : []
  return (
    <div className={`image-modal ${active ? 'is-active' : ''}`}>

      {img && (
        <div className="inner h100">
          <div className="close" onClick={_close}>×</div>
          <div className="x h100">
            <div className="c8 h100">
              <div className="cover h100" style={{
                backgroundImage: `url(${getImageUrl({
                  imageId: img.imageId,
                  filename: img.filename,
                  type: ImageType.large,
                })})`
              }}></div>

            </div>
            <div className="c4 ">
              <div className="image-modal-body x xdc xjb h100">
                <div className="top mb4">
                  <h2 className="strong fS">{img.name}</h2>
                  {img.legend &&
                    <ReactMarkdown
                      source={img.legend}
                      escapeHtml={false}
                    />
                  }
                </div>
                <div className="bottom">
                  {filterZone && <MiniMap input={filterZone[0]} />}
                  {hasFilters && <ImageFiltres input={img.filters} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
