import React, { useState, useEffect } from 'react';
import { getImageUrl, ImageType } from './GetImageUrl';


const ImageResponsive = ({image}) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 1000)
  }, [])

  const _getImageSizes = () => {
    const sizes = [
      {type: ImageType.small, size: '375w'},
      {type: ImageType.medium, size: '768w'},
      {type: ImageType.large, size: '1440w'}
    ]
    const srcSet = sizes.map((el) => {
      const imageUrl = getImageUrl({
        imageId: image.imageId,
        filename: image.filename,
        type: el.type,
      })
      return `${imageUrl} ${el.size}`
    })
    const src = getImageUrl({
      imageId: image.imageId,
      filename: image.filename,
      type: ImageType.medium,
    })
    return {
      src,
      srcSet,
      alt: image.filename,
    }
  }

  const imageSizes = _getImageSizes();
  const className = ready ? 'loaded' : ''
  return (
    <figure className={className}>
      <img 
      srcSet={imageSizes.srcSet}
      sizes='sizes="(min-width: 600px) 50vw , (min-width: 1024px) 30vw , 100vw"'
      src={imageSizes.src} 
      alt={imageSizes.alt} 
      loading="lazy"
      onLoad={() => setReady(true)} />
    </figure>

  );
};

export default ImageResponsive
